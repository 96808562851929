import React,{useState,useEffect,useRef} from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';
import AXIOS from '../../axios-config';
import { Link } from "gatsby";

//components
import SEO from '../components/seo';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import VideoBackground from '../components/videoBackground';
import PossibilitiesItem from '../components/possibilitesitem';
import Quote from '../components/quote';
import FlexCard from '../components/flexcard';
import settings from '../../settings';
import BmwDrivingExpGlance from '../components/bmwGlance/bmwDrivingExpGlance';
import ReCAPTCHA from 'react-google-recaptcha';

//images
const poster = `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-group-events.jpg`;
const interactive = `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-m5-interactive-popup.jpg`;
const expand = `${settings.IMAGES_BASE_URL}/images/groupevents/expand.jpg`;
const individual = `${settings.IMAGES_BASE_URL}/images/groupevents/individual.jpg`;
const fun = `${settings.IMAGES_BASE_URL}/images/groupevents/fun.jpg`;
const teamwork = `${settings.IMAGES_BASE_URL}/images/groupevents/teamwork-popup.jpg`;
const discover = `${settings.IMAGES_BASE_URL}/images/groupevents/discover.jpg`;
const confidence = `${settings.IMAGES_BASE_URL}/images/groupevents/confidence-builder.jpg`;
const unique = `${settings.IMAGES_BASE_URL}/images/groupevents/unique.jpg`;
const delicious = `${settings.IMAGES_BASE_URL}/images/groupevents/delicious.jpg`;
const social = `${settings.IMAGES_BASE_URL}/images/groupevents/social-popup.jpg`;
const quotebackground = `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-pdc-building.jpg`;
const quotebackground1 = `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-pdc-building-400.webp`;
const quotebackground2 = `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-pdc-building-800.webp`;
const quotebackground3= `${settings.IMAGES_BASE_URL}/images/groupevents/bmw-pdc-building-1200.webp`;
const groupdeal = `${settings.IMAGES_BASE_URL}/images/groupevents/group-deal.jpg`;
const videoUrl = `${settings.IMAGES_BASE_URL}/videos/group-events.mp4`;

const data = [
  {
    id: 1,
    possibility: 'Interactive',
    image: `${interactive}`,
    imageAlt: 'BMW M5 Interactive Popup',
    title: 'An INTERACTIVE Experience',
    description:
      'When on the track, your team will forge new connections while sharing the experience of driving at the limit. It’s so engaging that the only time you may see a mobile device out is to film a hot lap.'
  },
  {
    id: 2,
    possibility: 'Expand',
    image: `${expand}`,
    imageAlt: 'BMW Driving School Class Expand Popup',
    title: 'EXPAND Your Horizons',
    description:
      'Bringing your team here will allow them to try something they likely never have before: driving a BMW on a closed course. They will build a new sense of teamwork, make new connections and have the time of their lives.'
  },
  {
    id: 3,
    possibility: 'Individual',
    image: `${individual}`,
    imageAlt: 'BMW Driving School Instruction Popup',
    title: 'INDIVIDUAL Instruction',
    description:
      'Each driving exercise is performed under the watchful eye of a BMW professional driving instructor. They will provide instant feedback and positive reinforcement while encouraging each driver to have a little fun. Maybe more than a little.'
  },
  {
    id: 4,
    possibility: 'Fun',
    image: `${fun}`,
    imageAlt: 'BMW M2 Drift Fun Popup',
    title: 'Focusing FUN',
    description:
      'It’ll be all smiles as your team drives multiple BMW and MINI models on our track. Patience, focus and the foresight to look as far ahead as you can are skills taught from actual experience behind the wheel.'
  },
  {
    id: 5,
    possibility: 'Teamwork',
    image: `${teamwork}`,
    imageAlt: 'BMW Teamwork Popup',
    title: 'TEAMWORK Time',
    description:
      'Professional instructors take the time to explain each driving concept and then demonstrate. During your group driving experience, our instructors maintain open radio communication to give pointers throughout each exercise.'
  },
  {
    id: 6,
    possibility: 'Discover',
    image: `${discover}`,
    imageAlt: 'BMW X3 Discover Popup',
    title: 'DISCOVER and Drive',
    description:
      'No matter what type of exercises you choose for your program, you’ll find the latest BMW and MINI models on the tarmac waiting for you and your team to take them for a spin.'
  },
  {
    id: 7,
    possibility: 'Confidence',
    image: `${confidence}`,
    imageAlt: 'BMW M5 Drift Confidence Popup',
    title: 'CONFIDENCE Builder',
    description:
      'Learning how to handle a 617 horsepower M car on a track takes practice (that’s where we come in), and few get it right on the first try. We instruct and encourage your team to push their own limits, as well as a BMW’s, to learn about themselves.'
  },
  {
    id: 8,
    possibility: 'Unique',
    image: `${unique}`,
    imageAlt: 'BMW M4 Coupe Unique Popup',
    title: 'A UNIQUE Event',
    description:
      'Wet skid pad. Timed laps. Rat Race. There are so many different ways to configure your day here that you may need to come back again. Make that definitely.'
  },
  {
    id: 9,
    possibility: 'Delicious',
    image: `${delicious}`,
    imageAlt: 'BMW Driving School Lunch Popup',
    title: 'DELICIOUS Delights',
    description:
      'Enjoy a professionally catered lunch in our upstairs lounge during your visit. Sit outside and take in a breathtaking view of the track and surrounding landscape, then head downstairs to our coffee bar for a quick boost. Not that you’ll need it.'
  },
  {
    id: 10,
    possibility: 'Social',
    image: `${social}`,
    imageAlt: 'Social Popup',
    title: 'SOCIAL Experience',
    description: 'This is a once-in-a-lifetime event, and you’ll find plenty of moments throughout your day to share with friends and colleagues. Use #BMWPerformanceDrivingSchool and post on social media to show just how much fun track time can be.'
  }
];

const GroupEventsPage = () => {
  const [show,setShow] = useState(false)
  const [possibilities,setPossibilities] = useState([])
  const [activePossibility,setActivePossibility] = useState('')
  const [modalImage,setModalImage] = useState('')
  const [modalTitle,setModalTitle] = useState('')
  const [modalDescription,setModalDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showAnotherHeardFromUs,setShowAnotherHeardFromUs] = useState(true)
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    setPossibilities(data)
  },[activePossibility,possibilities])

  const handleModalOpen = (selectedIndex) => {
    setShow(true)
    setActivePossibility(selectedIndex)
    setModalImage(possibilities[selectedIndex].image)
    setModalTitle(possibilities[selectedIndex].title)
    setModalDescription(possibilities[selectedIndex].description)
  };

 const handleModalClose = () => {
   setShow(false)
   setActivePossibility('')
  };

  const handleNextPossibility = () => {
    let arr = possibilities.length;
    let selectedIndex = activePossibility + 1;
    selectedIndex = selectedIndex % arr;
    setShow(true)
    setActivePossibility(selectedIndex)
    setModalImage(possibilities[selectedIndex].image)
    setModalTitle(possibilities[selectedIndex].title)
    setModalDescription(possibilities[selectedIndex].description)
  };

const handlePreviousPossiblity = () => {
    let arr = possibilities.length;
    let selectedIndex = activePossibility;
    if (selectedIndex === 0) {
      selectedIndex = arr - 1;
    } else {
      selectedIndex = selectedIndex - 1;
    }
    setShow(true)
    setActivePossibility(selectedIndex)
    setModalImage(possibilities[selectedIndex].image)
    setModalTitle(possibilities[selectedIndex].title)
    setModalDescription(possibilities[selectedIndex].description)
  };

 const handleChangeSelection = (e) => {
    if(e.target.value === 'Other'){
      setShowAnotherHeardFromUs(false)
    } else {
      setShowAnotherHeardFromUs(true)
    }
  }

 const initialValues = {
    firstname: '',
    lastname: '',
    organization: '',
    zipcode: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    attendees: '',
    event_type: '',
    event_date: '',
    selectedLocation: [],
    notes: '',
    job_title: '',
    company_website: '',
    heardUsOther: '',
    heardUsOptions: '',
    WhenAndCompany: '',
    attendedBefore: '',
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    organization: Yup.string()
      .required('Please enter your organization')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    address: Yup.string()
      .required('Please enter your address')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    city: Yup.string()
      .required('Please enter your city')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    state: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    zipcode: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    event_type: Yup.string()
      .required('Please enter your event type')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    event_date: Yup.date().required('Please choose your event date'),
    job_title: Yup.string()
      .required('Please enter your job title')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    company_website: Yup.string()
      .required('Please enter your company website')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    email: Yup.string()
      .email()
      .required('Please enter your email address')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Your email address is incorrect'),
    phone: Yup.string()
      .required('Please enter your phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid phone number'
      ),
      selectedLocation: Yup.array().required("Please select at least one of the location"),
      attendees: Yup.string().required("Please enter number of attendees").matches(/^\d+$/, 'Please enter a valid number'),
      attendedBefore:  Yup.string().required("Please select one of the options").oneOf(["Yes" , "No"]),
      WhenAndCompany:  Yup.string()
      .when('attendedBefore', {
        is: "Yes",
        then: Yup.string().required('Please enter when and the company'),
        otherwise: Yup.string()
      })
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
      heardUsOptions: Yup.string().required('Please select one of the options').oneOf(['Instagram','Facebook','LinkedIn','Trade Show','Ad','Family/Friend','Other'])
    });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true)
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return;
    }
    AXIOS.post('/api/groupevent-form', values,{headers})
    .then(() => {
      resetForm();
      errorHandling(setSubmitting);
      cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
    })
    .catch((err) => {
      errorHandling(setSubmitting);
      cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
    });
  };

  const getCurrentOffer = () => {
    const today = new Date();
    const futureDate = today > new Date('November 30 2020 23:59') && today < new Date('January 1 2021 00:00');
    if(futureDate){
      return (
        <section className="performance-center">
              <div className="container">
                <div className="performance-center__header">
                  <h3 className="performance-center__current-offers">CURRENT OFFERS</h3>
                </div>
              </div>

              <FlexCard
                rowType="row"
                image={groupdeal}
                imageAlt="Tis the season of speed"
                header="Tis the season of speed"
                subheader="Get 20% off any group event"
                text="We’re making excitement the reason for the season, by giving 20% off a Group Event for your team."
                btnText="Call 888-345-4269 to book now"
                btnStyle="btn-blue"
                externalURL="tel:888-345-4269"
              >
                <p>
                  Use promo code <span className="bold">20LI123120</span>. Cannot be combined with any other offer.
                </p>
              </FlexCard>
        </section>
      )
    }
  }
  return (
    <Layout>
      <SEO title="BMW Group Events | BMW Performance Driving School" 
        description="Host your next business meeting with us. We offer meeting rooms, delicious catering options, and plenty of thrilling track time behind the wheel of a BMW in Greenville, SC, Palm Springs, CA and Indianapolis, IN." 
        keywords="Team building activities, bmw group events"  
      />
      <VideoBackground
        url={videoUrl}
        poster={poster}
        topLeftChildren={
          <div className="text_left">
            <div>BUSINESS MEETS PLEASURE.</div>
          </div>
        }
      />
      <section className="teambuilding">
        <div className="container">
          <div className="teambuilding__header">
            <h1>The BMW corporate driving experience</h1>
            <p>
              This isn’t your typical business meeting. You and your team will spend a corporate driving 
              experience day at one of our three locations, where you’ll be provided rooms to conduct 
              meetings, world-class cuisine and plenty of thrilling track time.
            </p>
          </div>
        </div>
        <Quote
          image={quotebackground}
          image1={quotebackground1}
          image2={quotebackground2}
          image3={quotebackground3}
          leftAlignedText="“We had just a wonderful time – what a great way"
          rightAlignedText="to team build and have a whole bunch of fun!”"
          client="- Matt B."
        />
      </section>
      <section className="possibilities">
        <div className="container">
          <div className="possibilities__header">
            <h2>TEN WORDS. INFINITE POSSIBILITIES.</h2>
            <p>
              Need a reason to take a business trip or weekend getaway? We’ll give you ten. Click on each 
              word to see how we’ll make the day and your team feel special with a BMW event.
            </p>
          </div>
          <div className="possibilities__content">
            <div className="row">
              {possibilities.map((possibility, selectedIndex) => {
                return (
                  <React.Fragment key={possibility.id}>
                    <PossibilitiesItem
                      text={possibility.possibility}
                      index={selectedIndex}
                      image={possibility.image}
                      imageAlt={possibility.imageAlt}
                      title={possibility.title}
                      onModalOpen={handleModalOpen}
                      onModalClose={handleModalClose}
                      onNext={handleNextPossibility}
                      onPrev={handlePreviousPossiblity}
                      modalImage={modalImage}
                      modalTitle={modalTitle}
                      modalDescription={modalDescription}
                      description={possibility.description}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <div className='group-events-glance'>
        <BmwDrivingExpGlance/>
      </div>
      {getCurrentOffer()}
      <section className={getCurrentOffer() ? 'getstarted_with_currentoffer' : 'getstarted'}>
        <div className="container">
          <div className="getstarted__header">
            <h2>READY TO GET STARTED?</h2>
            <p>
              If you’re interested in corporate group events for your or your team or a VIP weekend with friends, please fill out the information below and we will get back to you within 48 hours, or call <span className='bold'><a className="phone-number" href="tel:888-345-4269">888-345-4269</a></span>.
            </p>
              <br /> 
            <p>
              Looking for more information on a Performance Center Drive? Please do not fill out this form. Instead, contact us directly at <span className='bold'><a className="phone-number" href="tel:888-345-4269">888-345-4269</a></span>.
            </p>
          </div>
          <div className="getstarted__content">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleSubmit, isSubmitting,setFieldValue,handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <div className="checkbox">
                  
                    <h3>
                    <span>*Required field</span> <br />
                    *Select a location</h3>
                    <div className='selectLocations'>
                      <div className='locations-set-1'>
                        <div className="checkbox__group">
                          <Field type="checkbox" name="selectedLocation" value="Thermal, CA" id="thermalCA" />
                          <label htmlFor="thermalCA">Thermal, CA </label>
                        </div>
                        <div className="checkbox__group">
                          <Field type="checkbox" name="selectedLocation" value="Spartanburg, SC" id="spartanburg" />
                          <label htmlFor="spartanburg">Spartanburg, SC</label>
                        </div>
                      </div>
                      <div className="checkbox__group">
                        <Field type="checkbox" name="selectedLocation" value="Indianapolis, IN" id="indianapolis" />
                        <label htmlFor="indianapolis">Indianapolis, IN</label>
                      </div>
                    </div>
                    {errors.selectedLocation && touched.selectedLocation ? <span className="input-feedback">{errors.selectedLocation}</span> : <span className="input-feedback">&nbsp;</span>}
                  </div>
                    <h4>Information</h4>
                  <div className="input">
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="firstname">*First Name</label>
                        <Field
                          type="text"
                          name="firstname"
                          id="firstname"
                          className={touched.firstname && errors.firstname ? 'has-error' : null}
                        />
                        {errors.firstname &&
                        touched.firstname ? <span className="input-feedback">{errors.firstname}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="lastname">*Last Name</label>
                        <Field
                          type="text"
                          name="lastname"
                          id="lastname"
                          className={touched.lastname && errors.lastname ? 'has-error' : null}
                        />
                        {errors.lastname &&
                        touched.lastname ? <span className="input-feedback">{errors.lastname}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="job_title">*Your Job Title</label>
                        <Field
                          aria-label="job_title"
                          type="text"
                          name="job_title"
                          className={touched.job_title && errors.job_title ? 'has-error' : null}
                        />
                        {errors.job_title &&
                        touched.job_title ? <span className="input-feedback">{errors.job_title}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="organization">*Organization</label>
                        <Field
                          type="text"
                          name="organization"
                          id="organization"
                          className={touched.organization && errors.organization ? 'has-error' : null}
                        />
                        {errors.organization &&
                        touched.organization ? <span className="input-feedback">{errors.organization}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className='form__group'>
                      <div className="input__group">
                        <label htmlFor="email">*Your Email</label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          className={touched.email && errors.email ? 'has-error' : null}
                        />
                        {errors.email && touched.email ? <span className="input-feedback">{errors.email}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="company_website">*Company Website</label>
                        <Field
                          aria-label="company_website"
                          type="text"
                          name="company_website"
                          className={touched.company_website && errors.company_website ? 'has-error' : null}
                        />
                        {errors.company_website &&
                        touched.company_website ? <span className="input-feedback">{errors.company_website}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="input__group">
                      <label htmlFor="address">*Address</label>
                      <Field
                        type="text"
                        name="address"
                        id="address"
                        className={touched.address && errors.address ? 'has-error' : null}
                      />
                      {errors.address && touched.address ? <span className="input-feedback">{errors.address}</span> : <span className="input-feedback">&nbsp;</span>}
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="city">*City</label>
                        <Field type="text" name="city" id="city" className={touched.city && errors.city ? 'has-error' : null} />
                        {errors.city && touched.city && <span className="input-feedback">{errors.city}</span>}
                      </div>
                      <div className="input__group input--sm">
                        <label htmlFor="state">*State</label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          className={touched.state && errors.state ? 'has-error' : null}
                        />
                        {errors.state && touched.state ? <span className="input-feedback">{errors.state}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="zipcode">*Zip Code</label>
                        <Field
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                        />
                        {errors.zipcode &&
                        touched.zipcode ? <span className="input-feedback">{errors.zipcode}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="phone">*Phone</label>
                        <Field
                          type="tel"
                          name="phone"
                          id="phone"
                          className={touched.phone && errors.phone ? 'has-error' : null}
                        />
                        {errors.phone && touched.phone ? <span className="input-feedback">{errors.phone}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="eventType">*Event Type (training, seminar, etc.)</label>
                        <Field
                          type="text"
                          name="event_type"
                          id="eventType"
                          className={touched.event_type && errors.event_type ? 'has-error' : null}
                        />

                        {errors.event_type &&
                        touched.event_type ? <span className="input-feedback">{errors.event_type}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="eventDate">*Event Date</label>
                        <Field type="date" name="event_date" id="eventDate" />

                        {errors.event_date &&
                        touched.event_date ? <span className="input-feedback">{errors.event_date}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group input">
                        <label htmlFor="attendees">*Number of Attendees</label>
                        <Field
                          type="text"
                          name="attendees"
                          id="attendees"
                          className={`half-width ${touched.attendees && errors.attendees ? 'has-error' : null}`}
                        />
                      {errors.attendees && touched.attendees ? <span className="input-feedback">{errors.attendees}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group input ">
                        <label htmlFor="heardUsOptions">*How did you hear of us?</label>
                        <Field
                          as="select"
                          name="heardUsOptions"
                          id="heardUsOptions"
                          onChange={(e)=>{
                              handleChange(e) 
                              handleChangeSelection(e)
                          }}
                          className="half-width"
                        >
                          <option value="">Select one</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Facebook">Facebook</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Trade Show">Trade Show</option>
                          <option value="Ad">Ad</option>
                          <option value="Family/Friend">Family/Friend</option>
                          <option value="Other">Other</option>
                        </Field>
                        {errors.heardUsOptions && touched.heardUsOptions ? <span className="input-feedback">{errors.heardUsOptions}</span> : <span className="input-feedback">&nbsp;</span>}                     
                        </div>
                    </div>
                  <div className="form__group">
                    <div className='input__group'>
                      <label>*Have you attended an event with us before?</label>
                        <div role="group" aria-label="my-radio-group" className='radio__group' >
                        <div className='radio__button'>
                          <Field type="radio" name="attendedBefore" aria-label="attendedBeforeYes" value="Yes" checked={values.attendedBefore === "Yes"} onChange={() => setFieldValue("attendedBefore", "Yes")} />
                          <label>
                            Yes
                          </label>
                        </div>
                        <div className='radio__button'>
                          <Field type="radio" name="attendedBefore" aria-label="attendedBeforeNo" value="No" checked={values.attendedBefore === "No"} onChange={() => setFieldValue("attendedBefore", "No")}  />
                          <label>
                            No
                          </label>
                        </div>
                      </div>
                      {errors.attendedBefore && touched.attendedBefore ? <span className="input-feedback">{errors.attendedBefore}</span> : <span className="input-feedback">&nbsp;</span>}
                    </div> 
                    <div className="input__group">
                        <label hidden={showAnotherHeardFromUs} htmlFor="heardUsOther">Please tell us where</label>
                        <Field
                          hidden={showAnotherHeardFromUs}
                          type="text"
                          name="heardUsOther"
                          className={touched.showAnotherHeardFromUs && errors.showAnotherHeardFromUs ? 'has-error' : <span className="input-feedback">&nbsp;</span>}
                        />
                      </div>
                  </div>
                  <div className="form__group">
                  <div className="input__group">
                        <label htmlFor="WhenAndCompany">*Please tell us when and with what company</label>
                        <Field
                          type="text"
                          name="WhenAndCompany"
                          aria-label="WhenAndCompany"
                          className={touched.WhenAndCompany && errors.WhenAndCompany ? 'has-error' : null}
                        />
                        {errors.WhenAndCompany &&
                        touched.WhenAndCompany ? <span className="input-feedback">{errors.WhenAndCompany}</span> : null}
                    </div>  
                  </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="notes">Additional Notes</label>
                        <Field as="textarea" name="notes" id="notes" />
                      </div>

                    </div>
                  </div>
                  <div>
                    <p className="legal_note">We respect your privacy. When you submit your contact information, we agree to only contact you in accordance with our <Link to="https://www.bmwusa.com/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.</p>
                  </div>
                  <br/>
                  <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
                  <button className="btn btn-blue" type="submit" aria-label="submit-btn" disabled={isSubmitting}>
                    {isLoading ? <Spinner /> : 'Submit'}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default GroupEventsPage;
